import React, {useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import { getAcademyCategory } from "../../api/Api";

import { Difficulty } from "../../shared/Difficulty";

interface PropsAcademy {
    token: string;
}

interface Challenge {
    id: string;
    name: string; 
    difficulty: number;
    points: number;
    solved: boolean;
}

const Challenges = (props: PropsAcademy) => {
    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const token = props.token; 
    const { category } = useParams();
    let title = "";
    if(category === "solidity-starter") title = "Starter";
    else if(category === "introduction") title = "Introduction";
    else if(category === "access-control") title = "Access Control";
    else if(category === "reentrancy") title = "Reentrancy";
    else if(category === "randomness") title = "Randomness";
    else if(category === "denial-of-service") title = "Denial of Service";
    else if(category === "signatures") title = "Signatures";
    else if(category === "upgradeability") title = "Upgradeability";
    else if(category === "txorigin") title = "Tx Origin";
    else if(category === "gas-optimization") title = "Gas Optimization";

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAcademyCategory(token, category);
            if(data) { 
                setChallenges(data.challenges);
            }
        }
        
        fetchData();
    }, []);

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <h1>{title}</h1>
            <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right" sx={{color: "#fff"}}></TableCell>
                  <TableCell align="left" sx={{color: "#fff"}}>Mission</TableCell>
                  <TableCell align="right" sx={{color: "#fff"}}>Difficulty</TableCell>
                  <TableCell align="right" sx={{color: "#fff"}}>Points</TableCell>
                  <TableCell align="right" sx={{color: "#fff"}}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {challenges.map((challenge, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right" sx={{color: "#fff"}}>{idx + 1}</TableCell>
                   <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                        <Link style={{color: "#fff", textDecoration: "none"}} to={`/academy/challenge/${challenge.id}`}>
                            <Box sx={{":hover": {color: "#1976d2"}}}>{challenge.name}</Box>
                        </Link>
                    </TableCell>
                    <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                        <Difficulty level={challenge.difficulty} />
                    </TableCell>
                    <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>{challenge.points}</TableCell>
                    <TableCell align="right" sx={{color: "#fff"}}>
                        {challenge.solved && <CheckCircleIcon sx={{color: "#1976d2"}} />}
                        {!challenge.solved && <CancelIcon />}
                    </TableCell>
                  </TableRow>
              ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!challenges.length && <center><CircularProgress sx={{marginTop: "30px"}} size={60} /></center>}
        </Container>
    );
}

export default Challenges;