import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Recaptcha from "../../shared/Recaptcha";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import {registerUser} from "../../api/Api";


const Register = () => {
    const [message, setMessage] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [processing, setProcessing] = useState(false);
    const [counter, setCounter] = useState(0);

    const handleSubmit = async(e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const username = `${data.get("username")}`;
        if(username.length < 3) {
            setMessage("Username is too short");
            return;
        }
        if(username.length > 20) {
            setMessage("Username is too long");
            return;
        }

        const email = `${data.get("email")}`;
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            setMessage("Invalid email");
            return;
        }

        const password = `${data.get("password")}`;
        if(password.length < 4) {
            setMessage("Password too short");
            return;
        }
        if(password.length  > 64) {
            setMessage("Password too long");
            return;
        }
        const confirm_password = `${data.get("confirmPassword")}`;
        if(confirm_password != password) {
            setMessage("Confirmed password is not the same as password");
            return;
        }

        const accept_terms = data.get("acceptTerms") !== null;
        if(accept_terms !== true) {
            setMessage("The terms and privacy policy were not accepted");
            return;
        }
        
        setProcessing(true);
        const response = await registerUser({
            username: username,
            email: email,
            password: password,
            confirm_password: confirm_password, 
            recaptcha_token: recaptchaToken,
            accept_terms: accept_terms
        });
        setProcessing(false);
        
        if(response) {
            setMessage(response.data.msg);
            setCounter(counter + 1);
        }
    }

    return (
        <>
            <Container sx={{
              ladding: "0px",
              marginTop: "100px",
              marginBottom: "30px"
            }} disableGutters maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        boxRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#13151a",
                        color: "#fff",
                        border: "solid 1px rgba(255, 255, 255, .08)"
                    }}
                >
                    <Typography component="h1" variant="h5" style={{display: "flex", alignItems: "center"}}>
                        <AppRegistrationIcon fontSize="large" />
                        <span>Create Account</span>
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1}}>
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)"}, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                        />
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                        />
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            id="password"
                            label="Password"
                            type="password"
                        />
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            id="confirmPassword"
                            label="Confirm Password"
                            type="password"
                        />
                        <Checkbox name="acceptTerms" id="accceptTerms" sx={{color: "#ccc",}}/>
                        I accept <Link to={"/terms-and-conditions"} style={{color: "#1976d2", textDecoration: "none"}}>Terms and Conditions</Link> and <Link to={"/privacy-policy"} style={{color: "#1976d2", textDecoration: "none"}}>Privacy Policy</Link>

                        <Recaptcha key={counter} setRecaptchaToken={setRecaptchaToken} />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={processing}
                            sx={{ mt: 3, mb: 2}}
                        >
                            Register
                            {processing && <CircularProgress />}
                        </Button>
                    </Box>
                    {message}
                </Box>
                <Box style={{textAlign: "center", marginTop: "10px"}}>
                Already have an account? <Link to={"/login"} style={{color: "#1976d2", fontWeight: "bold", textDecoration: "none"}}>Sign In</Link>
                </Box>
            </Container>
        </>
    );
}

export default Register;