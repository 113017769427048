import Box from '@mui/material/Box';


interface PropsDifficulty {
    level: number;
}

const dot = {
    height: "12px",
    width: "12px",
    backgroundColor: "#bbb",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "5px"
};

const veryEasyDot = {
    ...dot,
    backgroundColor: "#5CCD67"
};

const easyDot = {
    ...dot,
    backgroundColor: "#8BC34A"
};

const mediumDot = {
    ...dot,
    backgroundColor: "#FFD700"
};

const difficultDot = {
    ...dot,
    backgroundColor: "#FF5733"
}

const veryDifficultDot = {
    ...dot,
    backgroundColor: "#A63E36"
}

const Difficulty = (props: PropsDifficulty) => {
    const level = props.level; 
    
    let dotColor = veryEasyDot;
    if(level <= 20) dotColor = veryEasyDot;
    else if(level <= 40) dotColor = easyDot;
    else if(level <= 60) dotColor = mediumDot;
    else if(level <= 80) dotColor = difficultDot;
    else if(level <= 100) dotColor = veryDifficultDot;

    return (
        <Box>
           { [...Array(5)].map((e, i) => 
                (i * 20 <= level ? (
                    <span style={dotColor} key={i}></span>
                ) : (
                    <span style={dot} key={i}></span>
                ))
            )}
        </Box>
    );
};

export { Difficulty };