import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { confirmUser } from "../../api/Api";
import Recaptcha from "../../shared/Recaptcha";

import { forgotPassword } from "../../api/Api";

const ForgotPassword = () => {
    const [message, setMessage] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState("");
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const email = `${data.get("email")}`;
        
        setProcessing(true);
        const response = await forgotPassword({
            email: email,
            recaptcha_token: recaptchaToken,
        });
        setProcessing(false);
        setMessage(response.msg);
    };

    return (
         <Container sx={{
            ladding: "0px",
            marginTop: "100px",
            marginBottom: "30px"
          }} disableGutters maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        boxRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#13151a",
                        color: "#fff",
                        border: "solid 1px rgba(255, 255, 255, .08)"
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Typography component="h1" variant="h5">
                        Forgot Password
                    </Typography>
                    <TextField
                        sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="email"
                        name="email"
                        autoFocus
                    />
                    <Recaptcha setRecaptchaToken={setRecaptchaToken} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Link to={"/login"}>
                                <Button fullWidth variant="contained">
                                    Back
                                </Button>
                            </Link>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={processing}
                            >
                                Send
                            </Button>
                        </Grid>
                    </Grid>
                    {message}
                </Box>
            </Container>
    );
}

export default ForgotPassword;