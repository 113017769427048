import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Progress } from "../../shared/Progress";
import { Link, useParams } from 'react-router-dom';

interface PropsCategoryStat {
    name: string;
    link: string;
    challenges: number;
    solved: number;
}

const sxLink = {
    color: "#fff",
    textDecoration: "none",

    "&:hover": {
        color: "#1976d2"
    } 
}

const CategoryStat = (props: PropsCategoryStat) => {
    const name = props.name;
    const link = props.link;
    const challenges = props.challenges;
    const solved = props.solved;

    return (
        <Grid container sx={{marginBottom: "10px"}}>
            <Grid item xs={6} sx={{fontWeight: "bold"}}>
                <Link to={link} style={{textDecoration: "none"}}>
                    <Box component="span" sx={sxLink}>
                        {name}
                    </Box>
                </Link>
            </Grid>
            <Grid item xs={3}>
                <Progress level={solved * 100 / challenges} />
            </Grid>
            <Grid item xs={3}>
                {solved} / {challenges}
            </Grid>
        </Grid>
    )
};


export default CategoryStat;