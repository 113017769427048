import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLeaderboard } from '../../api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

const ranks = [
  { value: 0, label: "Novice", },
  { value: 20, label: "Explorer", },
  { value: 40, label: "Master", },
  { value: 60, label: "Hacker", },
  { value: 80, label: "Sentinel"},
  { value: 100, label: "Overlord", },
]

const rankToText = (rank: number) => {
    if(rank < 20) return ranks[0].label;
    else if(rank < 40) return ranks[1].label;
    else if(rank < 60) return ranks[2].label;
    else if(rank < 80) return ranks[3].label;
    else if(rank < 100) return ranks[4].label;
    else return ranks[5].label;
};

interface PropsLeaderboard{
    token: string;
}

interface User {
    id: string;
    username: string; 
    points: number;
}

const Leaderboard = (props: PropsLeaderboard) => {
    const [users, setUsers] = useState<User[]>([]);
    const [total, setTotal] = useState(0);
    const token = props.token; 

    useEffect(() => {
        const fetchData = async () => {
            const data = await getLeaderboard(token);
            setUsers(data.users);
            setTotal(data.total);
        }
        
        fetchData();
    }, []);

    return (
      <Container sx={{
        width: "90%",
        ladding: "0px",
        marginTop: "100px",
        marginBottom: "100px"
      }} disableGutters maxWidth={false}>
            <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" sx={{width: "30px", color: "#fff"}}></TableCell>
                  <TableCell align="left" sx={{width: "30px", color: "#fff"}}></TableCell>
                  <TableCell align="left" sx={{color: "#fff"}}>Username</TableCell>
                  <TableCell align="left" sx={{color: "#fff"}}>Rank</TableCell>
                  <TableCell align="right" sx={{color: "#fff"}}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {users.map((user, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="right" sx={{color: "#fff"}}>{idx + 1}</TableCell>
                    <TableCell align="left" sx={{color: "#fff"}}>
                    <img style={{
                        width: "30px",
                        overflow: "hidden",
                        borderRadius: "50%" 
                      }} src={`/images/ranks/${rankToText(user.points * 100 / total).toLowerCase()}.png`} />
                    </TableCell>
                    <TableCell align="left" sx={{color: "#fff"}}>{user.username}</TableCell>
                    <TableCell align="left" sx={{color: "#fff"}}>{ user && total && rankToText(user.points * 100 / total)}</TableCell>
                    <TableCell align="right" sx={{color: "#fff"}}>{user.points}</TableCell>
                  </TableRow>
              ))}
              </TableBody>
            </Table>
            </TableContainer>
            {!users.length && <center><CircularProgress sx={{marginTop: "30px"}} size={60} /></center>}
        </Container>
    )
};

export default Leaderboard;