import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';

import { adminAddChallenge } from "../../api/Api";

interface PropsAdminChallenges {
    token: string;
}
const AdminAddChallenge = (props: PropsAdminChallenges) => {
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [app, setApp] = useState("");
    const [active, setActive] = useState(false);
    const [difficulty, setDifficulty] = useState(0);
    const [points, setPoints] = useState(0);
    const [type, setType] = useState(0);
    const [category, setCategory] = useState("introduction");

    const token = props.token; 

    const { id } = useParams();

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setProcessing(true);
        const response = await adminAddChallenge(token, {
            name: name,
            app: app,
            active: active,
            difficulty: difficulty,
            points: points,
            type: type,
            category: category
        });
        setProcessing(false);
        setMessage(response.msg);
    };
    
    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
        <h1>
            <Link style={{color: "#fff", textDecoration: "none" }} to={"/admin/challenges"}>Challenges</Link> &gt; Add
        </h1>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1}}>
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="app"
                    label="App"
                    name="app"
                    value={app}
                    onChange={(e) => setApp(e.target.value)}
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="difficulty"
                    label="Difficulty"
                    name="difficulty"
                    value={difficulty}
                    onChange={(e) => setDifficulty(Number(e.target.value))}
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="points"
                    label="Points"
                    name="points"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                />
                Active <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="acceptTerms" id="accceptTerms" sx={{color: "#ccc",}}/>
                <Box>
                    Type
                    <Select
                      id="type"
                      label="Type"
                      value={type}
                      onChange={(e) => setType(Number(e.target.value))}
                      sx={{color: "#fff"}}
                    >
                      <MenuItem value={0}>Academy</MenuItem>
                      <MenuItem value={1}>Mission</MenuItem>
                    </Select>
                </Box>
                <Box>
                    Category
                    <Select
                      id="category"
                      label="Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      sx={{color: "#fff"}}
                    >
                      <MenuItem value={"solidity-starter"}>Solidity Starter</MenuItem>
                      <MenuItem value={"introduction"}>Introduction</MenuItem>
                      <MenuItem value={"access-control"}>Access Control</MenuItem>
                      <MenuItem value={"reentrancy"}>Reentrancy</MenuItem>
                      <MenuItem value={"randomness"}>Randomness</MenuItem>
                      <MenuItem value={"denial-of-service"}>Denial of Service</MenuItem>
                      <MenuItem value={"signatures"}>Signatures</MenuItem>
                      <MenuItem value={"upgradeability"}>Upgradeability</MenuItem>
                      <MenuItem value={"txorigin"}>Tx Origin</MenuItem>
                      <MenuItem value={"gas-optimization"}>Gas Optimization</MenuItem>
                      <MenuItem value={"missions"}>Missions</MenuItem>
                    </Select>
                </Box>
 
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2}}
                    disabled={processing}
                >
                    Add
                </Button>
                {message}
            </Box>
      </Container>
    );
};

export default AdminAddChallenge;