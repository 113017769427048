import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getLeaderboard } from '../../api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import { NavLink, useLocation } from 'react-router-dom';
import { PropsOf } from '@emotion/react';


interface PropsHome {
    token: string;
}

const Home = (props: PropsHome) => {
    const token = props.token;

    return (
        <div style={{backgroundColor: "#000"}}>
        <div style={{
            position: "absolute",
            background: `linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)), url("/images/background.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            margin: "0px",
            top: "0px",
            left: "0px",
            height: "100%",
            width: "100%"
        }}>
            <div style={{
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    width: "70%",
                    padding: "20px",
                    fontSize: "27px",
                }}>
                    <h1>WEB3 SECURITY TRAINING PLATFORM</h1>
                    <p>
                        LEARN THE SECURITY OF SMART CONTRACTS THROUGH THE ENGANGING SET OF EXERCISES AND CHALLENGES
                    </p>
                    <NavLink style={{
                        textDecoration: "none",
                        color: "#fff",
                        backgroundColor: "rgb(25, 118, 210)",
                        padding: "10px 20px 10px 20px",
                        fontSize: "18px",
                    }} to={token == "" ? "/register" : "/dashboard"} >SIGN UP NOW</NavLink>
                </div>
          </div>
        </div>
    </div>
    )
};

export default Home;