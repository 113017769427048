// @ts-nocheck

import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import ReactAnsi from 'react-ansi';
import ReactMarkdown from 'react-markdown';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Grid from '@mui/material/Grid';
import { getChallenge, executeChallenge, downloadChallenge } from "../../api/Api";
import DehazeIcon from '@mui/icons-material/Dehaze';
import Code from "./Code";
import LinearProgress from '@mui/material/LinearProgress';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MapIcon from '@mui/icons-material/Map';
import FlagIcon from '@mui/icons-material/Flag';
import fileDownload from 'js-file-download';
import ModalComplete from "./ModalComplete";
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  function a11yPropsMeta(index: number) {
    return {
      id: `simple-tab-meta-${index}`,
      'aria-controls': `simple-tabpanel-meta-${index}`,
    };
  }

  

interface PropsChallenge {
    token: string;
}

interface File {
    name: string;
    content: string;
    editable: boolean;
    solution: boolean;
}
interface Challenge {
    id: string;
    name: string; 
    description: string;
    mission: string;
    solved: boolean;
    category: string;
}

const Challenge = (props: PropsChallenge) => {
    const [challenge, setChallenge] = useState<Challenge|null>(null);
    const token = props.token; 
    const { id } = useParams();
    const navigate = useNavigate();

    const [index, setIndex] = useState(0);
    const [code, setCode] = useState("");
    const [filetype, setFiletype] = useState<string|undefined>("sol");
    const [editDisabled, setEditDisabled] = useState(true);
    const [files, setFiles] = useState<File[]>([]);
    const [stdout, setStdout] = useState("");
    const [stderr, setStderr] = useState("");
    const [processing, setProcessing] = useState(false);
    const [slowDown, setSlowDown] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    
    const [metaDescription, setMetaDescription] = useState("");
    const [metaIdx, setMetaIdx] = useState(0);
    const [modalCompletedOpen, setModalCompletedOpen] = useState(false);
    

    useEffect(() => {
        const fetchData = async () => {
            const data = await getChallenge(token, id);
            setChallenge(data);
            setFiles(data.files.concat(data.tests)); 

            let filetype = data.files[0].name.split('.').pop();
            setFiletype(filetype)
            setCode(data.files[0].content);
            setEditDisabled(!data.files[0].editable);
            setMetaDescription(data.mission);
        }
        
        fetchData();
    }, []);
    
    const handleChange = (e: any, idx: number) => {
      setIndex(idx);
      if(challenge) {
        let filetype = files[idx].name.split('.').pop();
        setFiletype(filetype);
        setCode(files[idx].content);
        setEditDisabled(!files[idx].editable);
      }
    };
    
    const handleChangeMeta = (event: React.SyntheticEvent, idx: number) => {
        setMetaIdx(idx);
        if(idx == 0) {
             setMetaDescription(challenge.mission);
        }
        else if(idx == 1) {
            setMetaDescription(challenge.description);
        }
    };
    
    const updateCode = (idx: number, content: string) => {
        let items = [...files];
        let item = {...items[idx]};
        item.content = content;
        items[idx] = item;
        setFiles(items);
    }
    
    const execute = async (action: string) => {
        if(token == "") {
            navigate("/register");
            return;
        }

        for(let i=0; i<files.length; i++) {
            if(files[i].solution) {
                setProcessing(true);
                const response = await executeChallenge(token, action, id, files[i].content);
                
                if(response !== null) {
                    if(response.status == 200) {
                        setStderr(response.data["stderr"]);
                        setStdout(response.data["stdout"]);

                        if(response.data["errcode"] == 0) {
                            if(challenge) {
                                setModalCompletedOpen(true);
                                challenge.solved = true;
                                setChallenge(challenge);
                            }
                        }
                        if(slowDown) setSlowDown(false);
                    }
                    
                    if(response.status == 429) {
                        setSlowDown(true);
                    }
                }
                setProcessing(false);
            }
        }
    }
    
    const download = async () => {
        if(token == "") {
            navigate("/register");
            return;
        }

        const response = await downloadChallenge(token, id);
        if(response !== null) {
            fileDownload(response, "app.zip");
        }
    }

    const renderers: any = {
        image: ({
            alt,
            src,
            title,
        }: {
            alt?: string;
            src?: string;
            title?: string;
        }) => (
            <img 
                alt={alt} 
                src={src} 
                title={title} 
                style={{ maxWidth: 475 }}  />
        ),
    };


    function Image(props) {
        return <img {...props} style={{maxWidth: '100%'}} />
      }

    if(!challenge) {
        return <center><CircularProgress sx={{marginTop: "30px"}} size={60} /></center>
    }
    
    let link = "academy/";
    let categoryName = "";
    if(challenge.category === "solidity-starter") categoryName = "Solidity Starter";
    else if(challenge.category === "introduction") categoryName = "Introduction";
    else if(challenge.category === "access-control") categoryName = "Access Control";
    else if(challenge.category === "reentrancy") categoryName = "Reentrancy";
    else if(challenge.category === "randomness") categoryName = "Randomness";
    else if(challenge.category === "denial-of-service") categoryName = "Denial of Service";
    else if(challenge.category === "signatures") categoryName = "Signatures";
    else if(challenge.category === "upgradeability") categoryName = "Upgradeability";
    else if(challenge.category === "txorigin") categoryName = "Tx Origin";
    else if(challenge.category === "gas-optimization") categoryName = "Gas Optimization";
    else if(challenge.category === "missions") {
        categoryName = "Missions";
        link = "";
    }

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <Grid container>
                <Grid item xs={9}>
                    <h1>
                        <Link style={{color: "#fff", textDecoration: "none" }} to={`/${link}${challenge.category}`}>
                            <Box sx={{display: 'inline', ":hover": {color: "#1976d2"}}}>{categoryName}</Box>
                        </Link> &gt; {challenge.name}</h1> 
                </Grid>
                <Grid item xs={3} style={{textAlign: "right"}}>
                    <h1>
                       {challenge.solved && <>Solved <CheckCircleIcon style={{color: "#1976d2"}} /></>}
                    </h1>
                </Grid>
            </Grid>
            <Grid container spacing={4}>
                <Grid item xs={fullscreen ? 12 : 7}>
                <Grid container>
                    <Grid item xs={11}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={index} onChange={handleChange} aria-label="basic tabs example">
                        {files.map((file, idx) => {
                            return file.editable ?
                                <Tab sx={{fontSize: "13px", color: "#fff"}} key={idx} label={file.name} icon={<EditNoteIcon />} iconPosition="start" {...a11yProps(idx)} />
                            :
                                <Tab sx={{fontSize: "13px", color: "#fff"}} key={idx} label={file.name} icon={<DehazeIcon />} iconPosition="start" {...a11yProps(idx)} />
                        })}
                      </Tabs>
                    </Box>
                    </Grid>
                    <Grid item xs={1} sx={{textAlign: "right", paddingTop: "25px"}}>
                        { fullscreen ?
                            <CloseFullscreenIcon
                                sx={{
                                    fontSize: "23px",
                                    "&:hover": {
                                        color: "#1976d2",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setFullscreen(false)}
                            />
                        :
                            <FullscreenIcon
                                sx={{
                                    fontSize: "25px",
                                    "&:hover": {
                                        color: "#1976d2",
                                        cursor: "pointer"
                                    }
                                }}
                                onClick={() => setFullscreen(true)}
                            />
                        }
                    </Grid>
                </Grid>
                <CodeEditor
                  value={code}
                  language={filetype}
                  onChange={(e) => updateCode(index, e.target.value)}
                  padding={15}
                  data-color-mode="dark"
                  disabled={editDisabled}
                  style={{
                    fontSize: 15,
                    fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                    border: "solid 1px #333"
                  }}
                />
                <Button
                    variant="contained"
                    sx={{
                        mt: 3, mb: 2, marginRight: "10px", width: "150px",
                        ":disabled": {
                            backgroundColor: "#24292f",
                            color: "#444"
                        }
                    }}
                    onClick={() => execute("compile")}
                    disabled={processing}
                >
                    Build
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        mt: 3, mb: 2, width: "150px",
                        ":disabled": {
                            backgroundColor: "#24292f",
                            color: "#444"
                        }
                    }}
                    onClick={() => execute("test")}
                    disabled={processing}
                >
                    Execute
                </Button>
                <Button
                    variant="contained"
                    color="warning"
                    sx={{
                        mt: 3, mb: 2, width: "150px",
                        float: "right",
                        ":disabled": {
                            backgroundColor: "#24292f",
                            color: "#444"
                        }
                    }}
                        onClick={download}
                    >
                        Download
                    </Button>

                {slowDown && <h3>Slow Down!</h3>}
                { processing ?
                     <LinearProgress /> 
                     :
                     <div style={{height: "3px"}}></div>
                }
     
                <ReactAnsi style={{
                    fontSize: 13,
                }}
                bodyStyle={{
                    backgroundColor: "#161b22",
                    border: "solid 1px #333"
                }}
                log={stderr ? stderr : stdout} />
            </Grid>
            <Grid item xs={fullscreen ? 12 : 5} sx={{
                overflow: "hidden",
                lineHeight: "22px"
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                             <Tabs value={metaIdx} onChange={handleChangeMeta} aria-label="basic tabs example">
                                { challenge.mission && <Tab sx={{color: "#fff"}} key={0} label="Mission" icon={<FlagIcon />} iconPosition="start" {...a11yProps(0)} /> }
                                { challenge.description && <Tab sx={{color: "#fff"}} key={1} label="Study Material"  icon={<MenuBookIcon />} iconPosition="start" {...a11yProps(1)} /> }
                             </Tabs>
                           </Box>
                    </Grid>
                </Grid>
                    <ReactMarkdown
                        components={{
                            code: (node) => <Code node={node} />
                          }}
                    >
                        {metaDescription}
                    </ReactMarkdown>
            </Grid>
            </Grid>
            <ModalComplete open={modalCompletedOpen} setOpen={setModalCompletedOpen} />
        </Container>
    )
};

export default Challenge;