import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MapIcon from '@mui/icons-material/Map';
import Container from '@mui/material/Container';
import { NavLink, useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom';
import { Difficulty } from "../../shared/Difficulty";

import { getAcademyStats, getUserStats, getRecentChallenges } from "../../api/Api";
import CategoryStat from './CategoryStat';
import { SettingsApplicationsSharp } from '@mui/icons-material';
import { categoryToText } from "../../utils/Category";

const ranks = [
  { value: 0, label: "Novice", },
  { value: 20, label: "Explorer", },
  { value: 40, label: "Master", },
  { value: 60, label: "Hacker", },
  { value: 80, label: "Sentinel"},
  { value: 100, label: "Overlord", },
]

const rankToText = (rank: number) => {
    if(rank < 20) return ranks[0].label;
    else if(rank < 40) return ranks[1].label;
    else if(rank < 60) return ranks[2].label;
    else if(rank < 80) return ranks[3].label;
    else if(rank < 100) return ranks[4].label;
    else return ranks[5].label;
};

interface Challenge {
    id: string;
    name: string; 
    type: number;
    category: string;
    difficulty: number;
    points: number;
    solved: boolean;
}

interface Stats {
  challenges: number;
  solved: number;
}

interface UserStats {
  username: string;
  points: number;
  total: number; 
}

interface PropsDashboard {
    token: string;
}

const Dashboard = (props: PropsDashboard) => {
    const token = props.token;
    
    const [userStats, setUserStats] = useState<UserStats>();

    const [solidityStarter, setSolidityStarter] = useState<Stats>();
    const [introduction, setIntroduction] = useState<Stats>();
    const [accessControl, setAccessControl] = useState<Stats>();
    const [reentrancy, setReentrancy] = useState<Stats>();
    const [txOrigin, setTxOrigin] = useState<Stats>();
    const [gasOptimization, setGasOptimization] = useState<Stats>();
    const [dos, setDos] = useState<Stats>();
    const [signatures, setSignatures] = useState<Stats>();
    const [upgradeability, setUpgradeability] = useState<Stats>();
    const [randomness, setRandomness] = useState<Stats>();
    const [rank, setRank] = useState("Rank");

    const [challenges, setChallenges] = useState<Challenge[]>([]);

    useEffect(() => {
      const fetchStats = async () => {
        const data = await getAcademyStats(token);
              
        for(let i=0; i<data.stats.length; i++) {
            if(data.stats[i].category == "solidity-starter") {
                setSolidityStarter(data.stats[i]);
            }
            else if(data.stats[i].category == "introduction") {
                setIntroduction(data.stats[i]);
            }
            else if(data.stats[i].category == "access-control") {
                setAccessControl(data.stats[i]);
            }
            else if(data.stats[i].category == "reentrancy") {
                setReentrancy(data.stats[i]);
            }
            else if(data.stats[i].category == "denial-of-service") {
                setDos(data.stats[i]);
            }
            else if(data.stats[i].category == "randomness") {
                setRandomness(data.stats[i]);
            }
            else if(data.stats[i].category == "signatures") {
                setSignatures(data.stats[i]);
            }
            else if(data.stats[i].category == "upgradeability") {
                setUpgradeability(data.stats[i]);
            }
            else if(data.stats[i].category == "txorigin") {
                setTxOrigin(data.stats[i]);
            }
            else if(data.stats[i].category == "gas-optimization") {
                setGasOptimization(data.stats[i]);
            }
        }
      } 
        const fetchData = async () => {
            const data = await getRecentChallenges(token, 5);
            if(data) { 
                setChallenges(data.challenges);
            }
        }
        
        const fetchUserStats = async () => {
          const response = await getUserStats(token); 
          if(response) {
            setUserStats(response.data);
            const tmpRank = rankToText(response.data.points * 100 / response.data.total);
            setRank(tmpRank);
          }
        }
        
        fetchStats();
        fetchData();
        fetchUserStats();
    }, []);

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <Box sx={{border: "1px solid rgb(51, 51, 51)", padding: "0px 0px 0px 0px"}}>
            {token == "" && <Box sx={{
              height: "260px",
              textAlign: "center",
              background: "linear-gradient(0deg, rgba(19, 21, 26, 0.8), rgba(19, 21, 26, 0.8)), url('images/stats.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover"
            }}>
              <Box sx={{
                paddingTop: "50px",
              }}>
                  <p style={{
                    fontSize: "30px",
                    fontWeight: "bold"
                  }}>
                    SIGN UP TO TRACK PROGRESS AND RANK
                  </p>
                  <NavLink style={{
                        textDecoration: "none",
                        color: "#fff",
                        backgroundColor: "rgb(25, 118, 210)",
                        padding: "10px 20px 10px 20px",
                        fontSize: "20px",
                    }} to={"/register"}>SIGN UP</NavLink>
                </Box>
            </Box> }
            
            { token != "" && <Grid container spacing={2} sx={{
              padding: "30px 60px 30px 60px"
            }}>
              <Grid item xs={6}>
                {solidityStarter && <CategoryStat name="Solidity Starter" link={"/academy/solidity-starter"} solved={solidityStarter.solved} challenges={solidityStarter.challenges} /> }
                {introduction && <CategoryStat name="Security Introduction" link={"/academy/introduction"} solved={introduction.solved} challenges={introduction.challenges} /> }
                {accessControl && <CategoryStat name="Access Control" link={"/academy/access-control"} solved={accessControl.solved} challenges={accessControl.challenges} /> }
                {reentrancy && <CategoryStat name="Reentrancy" link={"/academy/reentrancy"} solved={reentrancy.solved} challenges={reentrancy.challenges} /> }
                {randomness && <CategoryStat name="Randomness" link={"/academy/randomness"} solved={randomness.solved} challenges={randomness.challenges} /> }
                {dos && <CategoryStat name="Denial of Service" link={"/academy/denial-of-service"} solved={dos.solved} challenges={dos.challenges} /> }
                {signatures && <CategoryStat name="Signatures" link={"/academy/signatures"} solved={signatures.solved} challenges={signatures.challenges} /> }
                {upgradeability && <CategoryStat name="Upgradeability" link={"/academy/upgradeability"} solved={upgradeability.solved} challenges={upgradeability.challenges} /> }
                {txOrigin && <CategoryStat name="TX Origin" link={"/academy/txorigin"} solved={txOrigin.solved} challenges={txOrigin.challenges} /> }
                {gasOptimization && <CategoryStat name="Gas Optimization" link={"/academy/gas-optimization"} solved={gasOptimization.solved} challenges={gasOptimization.challenges} /> }
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <h2>{userStats && userStats.username}</h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{marginLeft: "auto", width: "200px", height: "200px", textAlign: "center"}}>
                      <img style={{
                        width: "200px",
                        overflow: "hidden",
                        borderRadius: "50%" 
                      }} src={`/images/ranks/${rank.toLowerCase()}.png`} />
                      <h4 style={{padding: "0px", margin: "0px"}}>{ rank }</h4>
                    </Box>
                  </Grid>
                </Grid>
                <Box>
                  <h3 style={{margin: "0px"}}>Progress</h3>
                  <Slider
                    sx={{
                      "& .MuiSlider-mark": {
                          width: "15px",
                          height: "15px",
                          backgroundColor: "#1976d2",
                          borderRadius: "50%",
                          opacity: "1.0"
                      },
                    "& .MuiSlider-markLabel": {
                      color: "#fff"
                      },
                      '& .MuiSlider-thumb': {
                        color: "#1976d2",
                    },
                    '& .MuiSlider-track': {
                        color: "#1976d2"
                    },
                    '& .MuiSlider-rail': {
                        color: "#1976d2"
                    },
                    }}
                    aria-label="Always visible"
                    value={userStats ? userStats.points * 100 / userStats.total : 0}
                    valueLabelFormat={rankToText}
                    step={20}
                    marks={ranks}
                    disabled={true}
                  />
                </Box>
              </Grid>
            </Grid> }
            </Box>
            <h2>New Challenges</h2>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box sx={{border: "1px solid rgb(51, 51, 51)"}}>
                    <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left" sx={{color: "#fff"}}></TableCell>
                          <TableCell align="left" sx={{color: "#fff"}}>Mission</TableCell>
                          <TableCell align="right" sx={{color: "#fff"}}>Category</TableCell>
                          <TableCell align="right" sx={{color: "#fff"}}>Difficulty</TableCell>
                          <TableCell align="right" sx={{color: "#fff"}}>Points</TableCell>
                          <TableCell align="right" sx={{color: "#fff"}}>Solved</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {challenges.map((challenge, idx) => (
                          <TableRow
                            key={idx}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                                {challenge.type == 0 && <AccountBalanceSharpIcon fontSize="small" /> }
                                {challenge.type == 1 && <MapIcon fontSize="small" /> }
                            </TableCell>
                           <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                                { challenge.type == 0 && <Link style={{color: "#fff", textDecoration: "none"}} to={`/academy/challenge/${challenge.id}`}>
                                    <Box sx={{":hover": {color: "#1976d2"}}}>{challenge.name}</Box>
                                </Link> }
                                { challenge.type == 1 && <Link style={{color: "#fff", textDecoration: "none"}} to={`/missions/${challenge.id}`}>
                                    <Box sx={{":hover": {color: "#1976d2"}}}>{challenge.name}</Box>
                                </Link> }
                            </TableCell>
                            <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                                { categoryToText(challenge.category) }
                            </TableCell>
                            <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                                <Difficulty level={challenge.difficulty} />
                            </TableCell>
                            <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>{challenge.points}</TableCell>
                            <TableCell align="right" sx={{color: "#fff"}}>
                                {challenge.solved && <CheckCircleIcon sx={{color: "#1976d2"}} />}
                                {!challenge.solved && <CancelIcon />}
                            </TableCell>

                          </TableRow>
                      ))}
                      </TableBody>
                    </Table>
                    </TableContainer>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Dashboard;