import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import { getAcademyStats } from "../../api/Api";


interface PropsAcademy {
    token: string;
}

interface Stats {
    challenges: number;
    solved: number;
}


const Academy = (props: PropsAcademy) => {
    const [solidityStarter, setSolidityStarter] = useState<Stats>();
    const [introduction, setIntroduction] = useState<Stats>();
    const [accessControl, setAccessControl] = useState<Stats>();
    const [reentrancy, setReentrancy] = useState<Stats>();
    const [txOrigin, setTxOrigin] = useState<Stats>();
    const [gasOptimization, setGasOptimization] = useState<Stats>();
    const [dos, setDos] = useState<Stats>();
    const [signatures, setSignatures] = useState<Stats>();
    const [upgradeability, setUpgradeability] = useState<Stats>();
    const [randomness, setRandomness] = useState<Stats>();

    const token = props.token; 

    useEffect(() => {
        const fetchData = async () => {
            const data = await getAcademyStats(token);
            
            for(let i=0; i<data.stats.length; i++) {
                if(data.stats[i].category == "solidity-starter") {
                    setSolidityStarter(data.stats[i]);
                }
                else if(data.stats[i].category == "introduction") {
                    setIntroduction(data.stats[i]);
                }
                else if(data.stats[i].category == "access-control") {
                    setAccessControl(data.stats[i]);
                }
                else if(data.stats[i].category == "reentrancy") {
                    setReentrancy(data.stats[i]);
                }
                else if(data.stats[i].category == "denial-of-service") {
                    setDos(data.stats[i]);
                }
                else if(data.stats[i].category == "randomness") {
                    setRandomness(data.stats[i]);
                }
                else if(data.stats[i].category == "signatures") {
                    setSignatures(data.stats[i]);
                }
                else if(data.stats[i].category == "upgradeability") {
                    setUpgradeability(data.stats[i]);
                }
                else if(data.stats[i].category == "txorigin") {
                    setTxOrigin(data.stats[i]);
                }
                else if(data.stats[i].category == "gas-optimization") {
                    setGasOptimization(data.stats[i]);
                }
            }
            
        }
        
        fetchData();
    }, []);

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Link to={"/academy/introduction"} style={{textDecoration: "none", color: "#fff"}}>
                        <Box sx={{
                            backgroundColor: "rgba(255,255,255,.01)",
                            boxShadow: "0 0 1px #444",
                            ":hover": {
                                boxShadow: "0 0 5px #666",
                            }
                        }}>
                            <Box style={{padding: "20px"}}>
                            <img src="/images/academy/introduction.png" style={{  objectFit: "cover", width: "100%" }} />
                            </Box>
                            <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                            <h3>INTRODUCTION</h3>
                            {introduction && `Solved: ${introduction.solved} / ${introduction.challenges}`}
                            </Box>
                        </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/access-control"} style={{textDecoration: "none", color: "#fff"}}>
                        <Box sx={{
                            backgroundColor: "rgba(255,255,255,.01)",
                            boxShadow: "0 0 1px #444",
                            ":hover": {
                                boxShadow: "0 0 5px #666",
                            }
                        }}>
                            <Box style={{padding: "20px"}}>
                            <img src="/images/academy/access-control.png" style={{  objectFit: "cover", width: "100%" }} />
                            </Box>
                            <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                            <h3>ACCESS CONTROL</h3>
                            {accessControl && `Solved: ${accessControl.solved} / ${accessControl.challenges}`}
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                
                <Grid item xs={3}>
                    <Link to={"/academy/reentrancy"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/reentrancy.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>REENTRANCY</h3>
                        {reentrancy && `Solved: ${reentrancy.solved} / ${reentrancy.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/randomness"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/randomness.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>RANDOMNESS</h3>
                        {randomness && `Solved: ${randomness.solved} / ${randomness.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/denial-of-service"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/denial-of-service.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>DENIAL OF SERVICE</h3>
                        {dos && `Solved: ${dos.solved} / ${dos.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/signatures"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/signatures.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>SIGNATURES</h3>
                        {signatures && `Solved: ${signatures.solved} / ${signatures.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/upgradeability"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/upgradeability.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>UPGRADEABILITY</h3>
                        {upgradeability && `Solved: ${upgradeability.solved} / ${upgradeability.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>



                <Grid item xs={3}>
                    <Link to={"/academy/txorigin"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/tx-origin.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>TX ORIGIN</h3>
                        {txOrigin && `Solved: ${txOrigin.solved} / ${txOrigin.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>

                <Grid item xs={3}>
                    <Link to={"/academy/gas-optimization"} style={{textDecoration: "none", color: "#fff"}}>
                    <Box sx={{
                        backgroundColor: "rgba(255,255,255,.01)",
                        boxShadow: "0 0 1px #444",
                        ":hover": {
                            boxShadow: "0 0 5px #666",
                        }
                    }}>
                        <Box style={{padding: "20px"}}>
                        <img src="/images/academy/gas-optimization.png" style={{  objectFit: "cover", width: "100%" }} />
                        </Box>
                        <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                        <h3>GAS OPTIMIZATION</h3>
                        {gasOptimization && `Solved: ${gasOptimization.solved} / ${gasOptimization.challenges}`}
                        </Box>
                    </Box>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Academy;