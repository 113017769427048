


const categoryToText = (category: string) => {
    if(category === "solidity-starter") return "Solidity Starter";
    else if(category === "introduction") return "Introduction";
    else if(category === "access-control") return "Access Control";
    else if(category === "reentrancy") return "Reentrancy";
    else if(category === "randomness") return "Randomness";
    else if(category === "denial-of-service") return "Denial of Service";
    else if(category === "signatures") return "Signatures";
    else if(category === "upgradeability") return "Upgradeability";
    else if(category === "txorigin") return "Tx Origin";
    else if(category === "gas-optimization") return "Gas Optimization";
    else if(category === "missions") return "Mission";

    return "";
}

export { categoryToText };