import React, {useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Container from '@mui/material/Container';

import { getMissions } from "../../api/Api";
import { Difficulty } from "../../shared/Difficulty";


interface PropsMissions {
    token: string;
}

interface Mission {
    id: string;
    name: string;
    app: string;
    difficulty: number;
    solved: boolean;
    points: number;
}

const Missions = (props: PropsMissions) => {
    const token = props.token;
    const [missions, setMissions] = useState<Mission[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const data = await getMissions(token);
            if(data) { 
                setMissions(data.missions);
                console.log(data.missions)
            }
        }
        
        fetchData();
    }, []);

    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
            <Grid style={{width: "90%"}} container spacing={4}>
                { missions.map((mission, index) => {
                return (<Grid item key={index} xs={3}>
                    <Link to={`/missions/${mission.id}`} style={{textDecoration: "none", color: "#fff"}}>
                        <Box sx={{
                            backgroundColor: "rgba(255,255,255,.01)",
                            boxShadow: "0 0 1px #444",
                            ":hover": {
                                boxShadow: "0 0 5px #666",
                            }
                        }}>
                            <Box style={{padding: "20px"}}>
                                <div style={{
                                    backgroundImage: `url("/images/missions/${mission.app}.png")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover"
                                }}>
                                    <div style={{
                                        position: "relative",
                                        paddingTop: "5%",
                                        paddingRight: "5%",
                                        paddingLeft: "5%",
                                        textAlign: "right",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                    }}>
                                        {mission.points} pts
                                    </div>
                                    <div style={{
                                        paddingTop: "80%",
                                        position: "relative",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        paddingBottom: "5px",
                                        height: "25px",
                                    }}>
                                        {mission.solved && <>solved</>}
                                        {!mission.solved && <></>}
                                    </div>
                                </div>
                            </Box>
                            <Box style={{textAlign: "center", padding: "20px", backgroundColor: "rgba(255,255,255,.02)"}}>
                            <h3>{mission.name}</h3>
                            <Difficulty level={mission.difficulty} />
                            </Box>
                        </Box>
                    </Link>
                </Grid>)
                })}
            </Grid>
        </Container>
    );
}

export default Missions;