let BASE_URL = "";
let RECAPTCHA_SITE_KEY = "";

if(process.env.NODE_ENV == "production") {
    BASE_URL = "https://www.web3pwn.com/api";
    RECAPTCHA_SITE_KEY = "6LeLnn0pAAAAAAdB99C2wcCKS4aeKen3vfZBFPlm";
}
else {
    BASE_URL = "http://127.0.0.1:5000/api";
    RECAPTCHA_SITE_KEY = "6LeEaGEoAAAAAGs3w0SOsvq8MCsQ4RsYjGojuczZ";        
}

export {BASE_URL, RECAPTCHA_SITE_KEY};