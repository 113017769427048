import axios, {AxiosResponse, AxiosError} from "axios";
import { Buffer } from "buffer";

import { BASE_URL } from "../Config";


interface Credentials {
    email: string;
    password: string;
}

function getHeaders(token: string | undefined) {
    if(token == "" || token == null) {
        return {
            "Content-Type": "application/json"
        }
    }
    
    return {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }
}

export const loginUser = (credentials: Credentials) => {
    return axios(`${BASE_URL}/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      data: credentials,
    })
    .then((response: AxiosResponse) => response)
    .catch((error: AxiosError) => {
        if (error.response) {
            return error.response
       }
       return null;
    });
};


export const getAcademy = (token: string) => {
    return axios(`${BASE_URL}/academy`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
}

export const getAcademyCategory = (token: string, category: string | undefined) => {
    return axios(`${BASE_URL}/academy/${category}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
}

export const getAcademyStats = (token: string) => {
    return axios(`${BASE_URL}/academy/stats`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
}

export const getMissions = (token: string) => {
    return axios(`${BASE_URL}/missions`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
}


export const getChallenge = (token: string, challenge_id: string | undefined) => {
    return axios(`${BASE_URL}/challenge/${challenge_id}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}

export const downloadChallenge = (token: string, challenge_id: string | undefined) => {
    return axios(`${BASE_URL}/challenge/${challenge_id}/download`, {
        method: "GET",
        headers: getHeaders(token),
        responseType: 'arraybuffer'
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) {
            const fileData = Buffer.from(response.data, 'binary');
            return fileData;
        }
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}


export const executeChallenge = (token: string, action: string, challenge_id: string|undefined, exploit: string) => {
    const data = {
        exploit: exploit,
        action: action
    };
    return axios(`${BASE_URL}/challenge/${challenge_id}`, {
        method: "POST",
        headers: getHeaders(token),
        data: data
    })
    .then((response: AxiosResponse) => {
        return response;
    })
    .catch((error: AxiosError) => {
        if (error.response) {
            return error.response
        }
        return null
    });
}


export const getChallenges = (token: string) => {
    return axios(`${BASE_URL}/challenges`, {
        method: "GET",
        headers: getHeaders(token),
    })
    .then((response: AxiosResponse) => {
        return response;
    })
    .catch((error: AxiosError) => {
        return error.response;
    });
}

export const getRecentChallenges = (token: string, limit: number) => {
    return axios(`${BASE_URL}/challenges?order=desc&limit=${limit}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}


export const getLeaderboard = async (token: string) => {
    return axios(`${BASE_URL}/leaderboard`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}


export const getUserData = (token: string) => {
    return axios(`${BASE_URL}/userdata`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}

export const getUserStats = (token: string) => {
    return axios(`${BASE_URL}/user/stats`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        return response;
    })
    .catch((error: AxiosError) => {
        return error.response;
    });
}
interface Passwords {
    old_password: string;
    new_password: string;
    confirm_new_password: string;
}

export const changePassword = (token: string, passwords: Passwords) => {
    return axios(`${BASE_URL}/change_password`, {
        method: "GET",
        headers: getHeaders(token),
        data: passwords
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        throw error; 
    });
}


export const logout = (token: string) => {
    return axios(`${BASE_URL}/logout`, {
        method: "POST",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    })
}


export const confirmUser = (token: string | undefined) => {
    return axios(`${BASE_URL}/confirm/${token}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
}


interface RegisterData {
    username: string;
    email: string;
    password: string;
    confirm_password: string;
    recaptcha_token: string;
    accept_terms: boolean;
}
export const registerUser = (register_data: RegisterData) => {
    return axios(`${BASE_URL}/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: register_data
    })
    .then((response: AxiosResponse) => {
        return response;
    })
    .catch((error: AxiosError) => {
        return error.response;
    });
}

interface ForgotPasswordData {
    email: string;
    recaptcha_token: string;
}
export const forgotPassword = (forgotPasswordData: ForgotPasswordData) => {
    return axios(`${BASE_URL}/forgot-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        data: forgotPasswordData 
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        if (error.response) {
            return error.response.data
        }
        return null;
    });
};


export const resetPasswordTokenCheck = (token: string | undefined) => {
    return axios(`${BASE_URL}/reset-password/${token}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: Error | AxiosError) => {
        return null;
    });
   
}

interface ResetPasswordData {
    new_password: string;
    confirm_new_password: string;
}
export const resetPassword = (token: string | undefined, resetPasswordData: ResetPasswordData) => {
    return axios(`${BASE_URL}/reset-password/${token}`, {
        method: "POST",
        headers: getHeaders(token),
        data: resetPasswordData
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        if (error.response) {
            return error.response.data
        }
        return null;
    });
}

export const deleteUser = (token: string) => {
    return axios(`${BASE_URL}/user/delete`, {
        method: "DELETE",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
};


export const adminGetChallenges = (token: string) => {
    return axios(`${BASE_URL}/admin/challenges`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((AxiosError) => {
        return null;
    });
};


export const adminGetChallenge = (token: string, challenge_id: string | undefined) => {
    return axios(`${BASE_URL}/admin/challenge/${challenge_id}`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((error: AxiosError) => {
        return null;
    });
};


interface Challenge {
    name: string;
    app: string;
    active: boolean;
    difficulty: number;
    points: number;
    type: number;
    category: string;
}
export const adminUpdateChallenge = (token: string, challenge_id: string | undefined, challenge: Challenge) => {
    return axios(`${BASE_URL}/admin/challenge/${challenge_id}`, {
        method: "PUT",
            headers: getHeaders(token),
            data: challenge,
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
            if (error.response) {
                return error.response
            }
            return null;
        });
};


export const adminDeleteChallenge = (token: string, challenge_id: string | undefined) => {
    return axios(`${BASE_URL}/admin/challenge/${challenge_id}`, {
        method: "DELETE",
            headers: getHeaders(token)
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
            if (error.response) {
                return error.response
            }
            return null;
        });
};


export const adminAddChallenge = (token: string, challenge: Challenge) => {
    return axios(`${BASE_URL}/admin/challenges`, {
        method: "POST",
            headers: getHeaders(token),
            data: challenge,
        })
        .then((response: AxiosResponse) => response.data)
        .catch((error: AxiosError) => {
            if (error.response) {
                return error.response
            }
            return null;
        });
};


export const adminGetUsers = (token: string) => {
    return axios(`${BASE_URL}/admin/users`, {
        method: "GET",
        headers: getHeaders(token)
    })
    .then((response: AxiosResponse) => {
        if(response.status == 200) return response.data;
        return null;
    })
    .catch((AxiosError) => {
        return null;
    });
};