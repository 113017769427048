import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';

import { adminGetChallenge, adminUpdateChallenge, adminDeleteChallenge } from "../../api/Api";
import { getActiveElement } from '@testing-library/user-event/dist/utils';
import { useNavigate } from "react-router-dom";


const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #000',
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: "20px"
};

interface PropsAdminChallenges {
    token: string;
}

interface Challenge {
    id: string;
    name: string; 
    difficulty: number;
    points: number;
    solved: boolean;
}

const AdminUpdateChallenge = (props: PropsAdminChallenges) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [app, setApp] = useState("");
    const [active, setActive] = useState(false);
    const [difficulty, setDifficulty] = useState(0);
    const [points, setPoints] = useState(0);
    const [type, setType] = useState(0);
    const [category, setCategory] = useState("");

    const token = props.token; 
    const navigate = useNavigate()

    const { id } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const data = await adminGetChallenge(token, id);
            setName(data.name);
            setApp(data.app);
            setActive(data.active);
            setDifficulty(data.difficulty);
            setPoints(data.points);
            setType(data.type);
            setCategory(data.category);
        }
        
        fetchData();
    }, []);

    const handleUpdate = async (e: any) => {
        e.preventDefault();

        setProcessing(true);
        const response = await adminUpdateChallenge(token, id, {
            name: name,
            app: app,
            active: active,
            difficulty: difficulty,
            points: points,
            type: type,
            category: category
        });
        setProcessing(false);
        setMessage(response.msg);
    };
    
    const handleDelete = async (e: any) => {
        e.preventDefault();
        const response = await adminDeleteChallenge(token, id);
        navigate("/admin/challenges");
    }
    
    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
        <h1>
            <Link style={{color: "#fff", textDecoration: "none" }} to={"/admin/challenges"}>Challenges</Link> &gt; Edit 
        </h1>
            <Box component="form" onSubmit={handleUpdate} noValidate sx={{ mt: 1}}>
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="app"
                    label="App"
                    name="app"
                    value={app}
                    onChange={(e) => setApp(e.target.value)}
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="difficulty"
                    label="Difficulty"
                    name="difficulty"
                    value={difficulty}
                    onChange={(e) => setDifficulty(Number(e.target.value))}
                />
                <TextField
                    sx={{input: { color: '#fff' }, "& label":{ color: '#fff'}}}
                    margin="normal"
                    required
                    fullWidth
                    id="points"
                    label="Points"
                    name="points"
                    value={points}
                    onChange={(e) => setPoints(Number(e.target.value))}
                />
                Active <Checkbox checked={active} onChange={(e) => setActive(e.target.checked)} name="acceptTerms" id="accceptTerms" sx={{color: "#ccc",}}/>
                <Box>
                    Type
                    <Select
                      id="type"
                      label="Type"
                      value={type}
                      onChange={(e) => setType(Number(e.target.value))}
                      sx={{color: "#fff"}}
                    >
                      <MenuItem value={0}>Academy</MenuItem>
                      <MenuItem value={1}>Mission</MenuItem>
                    </Select>
                </Box>
                <Box>
                    Category
                    <Select
                      id="category"
                      label="Category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      sx={{color: "#fff"}}
                    >
                      <MenuItem value={"solidity-starter"}>Solidity Starter</MenuItem>
                      <MenuItem value={"introduction"}>Introduction</MenuItem>
                      <MenuItem value={"access-control"}>Access Control</MenuItem>
                      <MenuItem value={"reentrancy"}>Reentrancy</MenuItem>
                      <MenuItem value={"randomness"}>Randomness</MenuItem>
                      <MenuItem value={"denial-of-service"}>Denial of Service</MenuItem>
                      <MenuItem value={"signatures"}>Signatures</MenuItem>
                      <MenuItem value={"upgradeability"}>Upgradeability</MenuItem>
                      <MenuItem value={"txorigin"}>Tx Origin</MenuItem>
                      <MenuItem value={"gas-optimization"}>Gas Optimization</MenuItem>
                      <MenuItem value={"missions"}>Missions</MenuItem>
                    </Select>
                </Box>
 
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2}}
                    disabled={processing}
                >
                    Update 
                </Button>
                <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    onClick={() => setOpenDelete(true)}
                >
                    Delete
                </Button>
                {message}
            </Box>
            <Modal
              sx={modalStyle}
              open={openDelete}
              onClose={() => setOpenDelete(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Delete Challenge
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure?
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setOpenDelete(false)}
                    >
                        No, sorry!
                    </Button>

                    <Button
                        sx={{marginTop: "30px"}}
                        fullWidth
                        color="error"
                        variant="contained"
                        onClick={handleDelete}
                    >
                        Yes! Delete!
                    </Button>
                </Typography>
              </Box>
            </Modal>
      </Container>
    );
};

export default AdminUpdateChallenge;