import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { confirmUser } from "../../api/Api";


const Confirm = () => {
    const [message, setMessage] = useState("");
    const { token } = useParams();

   useEffect(() => {
        const confirm = async () => {
            const data = await confirmUser(token);
            console.log(data);
            setMessage(data.msg);
        }

        confirm();
    }, [])

    return (
        <Container sx={{
            ladding: "0px",
            marginTop: "100px",
            marginBottom: "30px"
          }} disableGutters maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        boxRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#13151a",
                        color: "#fff",
                        border: "solid 1px rgba(255, 255, 255, .08)"
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Email Confirmation
                    </Typography>
                    <Box sx={{ mt: 1}}>
                        {message}
                        <Link to={"/login"} >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                Sign In 
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Container>
    );
}

export default Confirm;