import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { resetPasswordTokenCheck, resetPassword } from "../../api/Api";
import { useNavigate } from "react-router-dom";


const ResetPassword = () => {
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState("");
    const { token } = useParams();

    const navigate = useNavigate()

    useEffect(() => {
        const checkToken = async () => {
            const data = await resetPasswordTokenCheck(token);
            if(data == null) {
                navigate("/login");
            }
        }
        checkToken(); 
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const new_password = `${data.get("new_password")}`;
        const confirm_new_password = `${data.get("confirm_new_password")}`;

        setProcessing(true);
        const response = await resetPassword(token, {
            new_password,
            confirm_new_password
        });
        setProcessing(false);
        setMessage(response.msg);
    };

    return (
        <Container sx={{
            ladding: "0px",
            marginTop: "100px",
            marginBottom: "30px"
          }} disableGutters maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        boxRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#13151a",
                        color: "#fff",
                        border: "solid 1px rgba(255, 255, 255, .08)"
                    }}
                    component="form"
                    onSubmit={handleSubmit}
                >
                    <Typography component="h1" variant="h5">
                        Set New Password
                    </Typography>
                    <TextField
                        sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                        margin="normal"
                        required
                        fullWidth
                        id="new_password"
                        label="New Password"
                        name="new_password"
                        autoFocus
                        type="password"
                    />
                     <TextField
                        sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                        margin="normal"
                        required
                        fullWidth
                        id="confirm_new_password"
                        label="Confirm New Password"
                        name="confirm_new_password"
                        type="password"
                    />
                    {message}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                    >
                        Update
                    </Button>
                </Box>
            </Container>
    );
}

export default ResetPassword;