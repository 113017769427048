import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import FaceIcon from '@mui/icons-material/Face';
import { useNavigate } from "react-router-dom";

import { loginUser } from "../../api/Api";

const sxLink = {
    textDecoration: "none",
    fontSize: "13px",
    color: "#fff",
    
};

interface LoginProps{
    setToken: React.Dispatch<React.SetStateAction<any>>;
}
const Login = ( loginProps: LoginProps) => {
    const setToken = loginProps.setToken;
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async(e: any) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        const email = `${data.get("email")}`;
        const password = `${data.get("password")}`;
        const response = await loginUser({
            email, password
        });
        if(response) {
            if(response.status == 200) {
                setToken(response.data.access_token);
                navigate("/");
            } else {
                setMessage(response.data.msg);
            }
        }
    }

    return (
        <>
            <Container sx={{
              ladding: "0px",
              marginTop: "100px"
            }} disableGutters maxWidth="sm">
                <Box
                    sx={{
                        boxShadow: 3,
                        boxRadius: 2,
                        px: 4,
                        py: 6,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "#13151a",
                        color: "#fff",
                        border: "solid 1px rgba(255, 255, 255, .08)"
                    }}
                >
                    <Typography component="h1" variant="h5" style={{display: "flex", alignItems: "center"}}>
                        <FaceIcon fontSize="large" />
                        <span>Sign in</span>
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1}} style={{width: "100%"}}>
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoFocus
                        />
                        <TextField
                            sx={{input: { color: '#fff', border: "solid 1px rgba(255, 255, 255, .3)" }, "& label":{ color: '#fff'}}}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            id="password"
                            label="Password"
                            type="password"
                        />
                        <Box style={{textAlign: "right"}}>
                            <Link to={"/forgot-password"} style={sxLink}>
                                <Box component="span" sx={{"&:hover": {color: "#1976d2"}}}>Forgot your password?</Box>
                            </Link>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2}}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs={6}>
                            Don't have an account?
                            </Grid>
                            <Grid item xs={6}>
                                <Link to={"/register"} >
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                    Register
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                        {message}
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default Login;