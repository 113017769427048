import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Button } from '@mui/material';
import Container from '@mui/material/Container';

import { adminGetChallenges } from "../../api/Api";
import { Difficulty } from "../../shared/Difficulty";

interface PropsAdminChallenges {
    token: string;
}

interface Challenge {
    id: string;
    name: string; 
    app: string;
    category: string;
    type: number;
    difficulty: number;
    points: number;
    active: boolean;
}

const AdminChallenges = (props: PropsAdminChallenges) => {
    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const token = props.token; 

    useEffect(() => {
        const fetchData = async () => {
            const data = await adminGetChallenges(token);
            setChallenges(data.challenges);
        }
        
        fetchData();
    }, []);
    
    return (
        <Container sx={{
          width: "90%",
          ladding: "0px",
          marginTop: "100px",
          marginBottom: "30px"
        }} disableGutters maxWidth={false}>
        <Grid container>
            <Grid item xs={9}>
                <h1>Challenges</h1>
            </Grid>
            <Grid item xs={3} style={{textAlign: "right"}}>
                <Link to={"/admin/challenges/add"}>
                    <Button variant="contained" style={{marginTop: "20px"}}>Add</Button>  
                </Link>
            </Grid>
        </Grid>
        <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right" sx={{color: "#fff"}}></TableCell>
              <TableCell align="left" sx={{color: "#fff"}}>Name</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>App</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Category</TableCell>
              <TableCell align="left" sx={{color: "#fff"}}>Type</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Difficulty</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Points</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {challenges.map((challenge, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
               <TableCell align="right" sx={{color: "#fff"}}>{idx + 1}</TableCell>
               <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                    <Link style={{color: "#fff", textDecoration: "none"}} to={`/admin/challenge/${challenge.id}`}>
                        <Box sx={{":hover": {color: "#1976d2"}}}>{challenge.name}</Box>
                    </Link>
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    { challenge.app}
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    { challenge.category}
                </TableCell>
                <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                    { challenge.type == 0 && "Academy"}
                    { challenge.type == 1 && "Mission"}
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    <Difficulty level={challenge.difficulty} />
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>{challenge.points}</TableCell>
                <TableCell align="right" sx={{color: "#fff"}}>
                    {challenge.active && <CheckCircleIcon sx={{color: "#1976d2"}} />}
                    {!challenge.active && <CancelIcon />}
                </TableCell>
              </TableRow>
          ))}
          </TableBody>
        </Table>
        </TableContainer>
        {!challenges.length && <center><CircularProgress sx={{marginTop: "30px"}} size={60} /></center>}
      </Container>
    );
};

export default AdminChallenges;