import React, { useEffect } from 'react';
import { RECAPTCHA_SITE_KEY } from "../Config";

declare global {
    interface Window {
        grecaptcha:any;
    }
}

interface PropsRecaaptcha {
    setRecaptchaToken: React.Dispatch<React.SetStateAction<any>>;
}
const Recaptcha = (props: PropsRecaaptcha) => {
      const setRecaptchaToken = props.setRecaptchaToken;
      const handleLoaded = () => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(RECAPTCHA_SITE_KEY, { action: "homepage" })
            .then((token: string) => {
                setRecaptchaToken(token);
            })
        })
      }

      useEffect(() => {
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`
        script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
      }, [])
      
      return (
        <div
          className="g-recaptcha"
          data-sitekey={RECAPTCHA_SITE_KEY}
          data-size="invisible"
        ></div>
      )
}

export default Recaptcha;