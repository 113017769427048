import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Button } from '@mui/material';
import Container from '@mui/material/Container';

import { adminGetUsers } from "../../api/Api";

interface PropsAdminUsers {
    token: string;
}

interface User {
    id: string;
    username: string; 
    email: string;
    created_at: string;
    confirmed: boolean;
    confirmed_at: string;
    admin: boolean;
}

const AdminUsers = (props: PropsAdminUsers) => {
    const [users, setUsers] = useState<User[]>([]);
    const token = props.token; 

    useEffect(() => {
        const fetchData = async () => {
            const data = await adminGetUsers(token);
            setUsers(data.users);
        }
        
        fetchData();
    }, []);
    
    return (
        <Container sx={{
            width: "90%",
            ladding: "0px",
            marginTop: "100px",
            marginBottom: "30px"
          }} disableGutters maxWidth={false}>
        <Grid container>
            <Grid item xs={9}>
                <h1>Users</h1>
            </Grid>
        </Grid>
        <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right" sx={{color: "#fff"}}></TableCell>
              <TableCell align="left" sx={{color: "#fff"}}>Username</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Email</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Created At</TableCell>
              <TableCell align="left" sx={{color: "#fff"}}>Confirmed</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Confirmed At</TableCell>
              <TableCell align="right" sx={{color: "#fff"}}>Admin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {users.map((user, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
               <TableCell align="right" sx={{color: "#fff"}}>{idx + 1}</TableCell>
               <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.username }
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.email }
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.created_at }
                </TableCell>
                <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.confirmed && <CheckCircleIcon sx={{color: "#1976d2"}} />}
                    {!user.confirmed && <CancelIcon />}
                </TableCell>
                <TableCell align="right" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.confirmed_at }
                </TableCell>
                <TableCell align="left" sx={{color: "#fff", fontWeight: "bold"}}>
                    { user.admin && <CheckCircleIcon sx={{color: "#1976d2"}} />}
                    {!user.admin && <CancelIcon />}
                </TableCell>
              </TableRow>
          ))}
          </TableBody>
        </Table>
        </TableContainer>
        {!users.length && <center><CircularProgress sx={{marginTop: "30px"}} size={60} /></center>}
      </Container>
    );
};

export default AdminUsers;