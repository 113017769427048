import CodeEditor from '@uiw/react-textarea-code-editor';


interface Node {
    className: string;
    children: string;
}
interface CodeProps {
    node: Node;
}
const Code = (props: CodeProps) => {
    let node = props.node;
    let language = "";
    if(node.className !== undefined) {
        language = node.className.split("-")[1];
    }
    
    return (
        node.className !== undefined ?
            <CodeEditor
              value={node.children}
              language={language}
              data-color-mode="dark"
              disabled={true}
              style={{
                fontSize: 15,
                fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
                margin: "0px",
                padding: "0px",
                border: "solid 1px #333"
              }}
            />
        :
            <span style={{
                fontSize: 15,
                backgroundColor: "#161b22",
                padding: "0px 7px 2px 7px",
                border: "solid 1px #333"
            }}>{node.children}</span>
    );
}

export default Code;